/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";
import { toastAlert } from "../../helper/toastAlert";
import { Link } from 'react-scroll'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import config from "../../lib/config";
import { getCurAddr } from "../../action/controller";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [accounts, setaccount] = React.useState();
  const [data,setdata] = React.useState([]);
  const [showpath,setshowpath] = React.useState(false);

  async function disconnect() {
    setaccount("");
    localStorage.clear();
  }

  useEffect(() => {
    getdetails();
    loadScript()
    console.log(window.location.pathname,'>>>>>>>>>>?')

    var pathname = window.location.pathname;
    if(pathname !="/buy"){
      setshowpath(true)
    }
  }, []);

  async function getdetails(){
   let datas = await getCurAddr(); 
   setdata(datas)
  }

  function loadScript() {
    $(document).ready(function(){
      $(".top_navbar ul li a").click(function() {        
        $('.MuiBackdrop-root').trigger('click');       
      });
    });
  }

  return (
    <div>
      <div className="top_navbar">
        <List className={classes.list}>
          {/* <ListItem className={classes.listItem}>
            <Link to="home" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Home</Link>
          </ListItem> */}
          <ListItem className={classes.listItem}>
          {showpath ?
            <Link to="about" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">About Us</Link>
            :
            <NavLink to="about" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login underline">About Us</NavLink>
          }
          </ListItem>
          <ListItem className={classes.listItem}>
          {showpath ?
            <Link to="why_choose" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Why ICO?</Link>
            :
            <NavLink to="why_choose" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login underline">Why ICO?</NavLink>
          }            
          </ListItem>
          <ListItem className={classes.listItem}>
          {showpath ?
            <Link to="token" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Tokenomics</Link>
          :
            <NavLink to="token" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login underline">Tokenomics</NavLink>
          }
          </ListItem>
          <ListItem className={classes.listItem}>
          {showpath ?
            <Link to="roadmap" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Roadmap</Link>
            :
            <NavLink to="roadmap" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login underline">Roadmap</NavLink>
          }
          </ListItem>
         {/* <ListItem className={classes.listItem}>
            <Link to="team" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Team</Link>
          </ListItem> */}
          <ListItem className={classes.listItem}>
            {showpath ?
              <Link to="faq" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">FAQ's</Link>
              :
              <NavLink to="faq" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login underline">FAQ's</NavLink>
            }
          </ListItem>
          <ListItem className={classes.listItem}>
            {showpath ?
              <Link to="contact" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Contact</Link>
             :
              <NavLink to="contact" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login underline">Contact</NavLink>
            }
          </ListItem>
         {/*<ListItem className={classes.listItem}>
            <a color="transparent" className="nav-link top_nav_login" href="javascript:void(0)">
              Whitepaper
            </a>
          </ListItem>*/}
          {localStorage.getItem("account") && localStorage.getItem("account")!=null && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!="" ? (
            <div className="dropdown">
              <ListItem className={classes.listItem} id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <Button variant="contained" className="menu_btn dropdown-toggle" ><p className="address_btn">{localStorage.getItem("account")}</p></Button>
              </ListItem>
              <div className="dropdown-menu walletDropMain" aria-labelledby="navbarDropdown">
                <p><label>BNB Balance</label><span>{data && data.bnbvalue ? parseFloat(data.bnbvalue).toFixed(4) : 0 }</span></p>
                <p><label>BUSD Balance</label><span>{data && data.busdvalue ? parseFloat(data.busdvalue).toFixed(4) : 0 }</span></p>
                <p><label>TECH Token Balance</label><span>{data && data.tokenvalue ? parseFloat(data.tokenvalue).toFixed(4) : 0 }</span></p>
                <p><a className="logoutLink" href="#" onClick={disconnect}>Disconnect</a></p>
              </div>
            </div>
          ) : (
            <ListItem className={classes.listItem}>
              <Button variant="contained" className="menu_btn" data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">Connect Wallet</Button>
            </ListItem>
          )}        

        </List>
      </div>


    </div>
  );
}

import React,{useState,useEffect} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksHome from "components/Header/HeaderLinksHome.js";
import styled from "styled-components";
import { toastAlert } from "../helper/toastAlert";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary, MenuItem, Select } from "@material-ui/core";
import axios from "axios";
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import config from "../lib/config";
import ABI from "../ABI/icoabi.json";
import BUSD from "../ABI/busdabi.json"
import { allfaq , queryupdate , cmsdetails } from "../action/controller.js"

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const busdContractAddr = config.busdcontract;
const contractAddr = config.charlieicocontract;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
let slider1;
let slider2;

export default function LandingPage(props) {

  const [expanded, setExpanded] = React.useState('panel1');
  const [faqlist,setfaqlist] = React.useState([]);
  const [email,setemail] = React.useState("");
  const [name,setname] = React.useState("");
  const [msg,setmsg] = React.useState("");
  const [cmslist,setcmslist] = React.useState({});

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const classes = useStyles();

  const { ...rest } = props;

  var team_settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      }
    ]
  };
  
  var roadmap_settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
          centerMode: false,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      }
    ]
  }

  useEffect(()=>{
    getfaq()
    getcms()
  },[])

  async function getfaq(){
    let records = await allfaq();
    if(records && records.data && records.data.data){
      setfaqlist(records.data.data)
    }
  }

  async function getcms(){
    let records = await cmsdetails();
    if(records && records.data && records.data.data){
      setcmslist(records.data.data)
    }
  }

  const namechange = (e) => {
    setname(e.target.value)
  }

  const emailchange = (e) => {
    setemail(e.target.value)
  }

  const msgchange = (e) =>{
    console.log(e.target.value,"==================")
    setmsg(e.target.value)
  }

  async function submitquery(){
    if(name!=null && name!=undefined && name!=""){
      if(email && email!=null && email!=undefined && email!=""){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
          if(msg && msg!=null && msg!=undefined && msg!=""){
            let data = {
              "name":name,
              "email":email,
              "msg":msg
            }
            let result = await queryupdate(data);
            setname("");
            setemail("");
            setmsg("");
            toastAlert('success', "Success.., We will Reach You Shortly", 'success');
          }
          
        }else{
          toastAlert('error', "Please Enter Valid Email", 'network');
        } 
      }else{
        toastAlert('error', "Please Enter Valid Email", 'network');
      }
    }else{
      toastAlert('error', "Please Enter Valid Name", 'network');
    }
  }
 
  return (
    <div className="header_home">
      <ScrollToTopOnMount />
      <Header
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.svg")} alt="logo" />}
        rightLinks={<HeaderLinksHome />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        {...rest}
      />
      
      <div className="page-header header-filter" name="home">        
        <div className="homepage_container container" >
          <GridContainer className="mt-5 align-items-center">
            <GridItem sm={12} md={6} lg={6} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
              <h1>Reshaping the Knowledge Transformation in IT Ecosystem.</h1>
              <h4>Techs Network is an utility behind Techs Network Coin(TECHIE), It Allows IT Professionals to share Their Knowledge via Communities. Live Help, Portfolio Management, Labs, TechsnetworkAccellerator, Jobs, Events, Presenetations, Campaigns, Offline Videos and Leasing/Licensing Aggregator are few more features .</h4>
              <div className="banner_btn_grps">
                <Button variant="contained" className="primary_btn"><Link to="/buy">Buy Token</Link></Button>
               {/* <Button variant="contained" className="primary_btn"><a href="javascript:void(0)">Whitepaper</a></Button> */}
              </div>
            </GridItem>
            <GridItem sm={12} md={6} lg={6} className="m-auto text-center" data-aos="fade-up" data-aos-duration="2000">
              <div className="banner_img">
                <img src={require("../assets/images/banner_img.png")} className="img-fluid " alt="Icon"/>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      <div className="main">

        {/* About Section */}
        <section className="about_section" name="about">
          <div className="container"> 
            <div className="section">
              <GridContainer>
                <GridItem sm={12} md={3} lg={3} data-aos="fade-up" data-aos-duration="2000">
                  <h5 className="count_txt">01</h5>
                  <h1 className="main_title">About Techs Network</h1>
                </GridItem>
                <GridItem sm={12} md={9} lg={9} data-aos="fade-up" data-aos-duration="2000">
                  <h3 className="">A Knowledge Sharing Platform That allows IT Professionals to Solve, Contribute and Grow.</h3>
                  <p className="home_para">Techies face large/small issues and problems every day and everywhere: in a code, soft, or just in our daily life. Sometimes we can sort them out rather quickly and other times we spend days trying to find out what went wrong and it drives us crazy. This Platform allows multiple ways to connect within the community of their interest.</p>
                  <p className="home_para">It also Allows passive earnings for techies by shaing their knowledge in multiple ways.</p>
                  <p className="home_para">Organizations can leverage this Platform by showcasing their capabilities via Presentatoins, Campaigns and Events.</p>
                  {/*<Button variant="contained" className="primary_btn">Read more</Button>*/}
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="why_choose_section dark_blue_bg" name="why_choose">
          <div className="container"> 
            <div className="section">
              <GridContainer data-aos="fade-up" data-aos-duration="2000">
                <GridItem sm={12} md={3} lg={3}>
                  <h5 className="count_txt">02</h5>
                  <h1 className="main_title">Why choose our token</h1>
                </GridItem>
                <GridItem sm={12} md={9} lg={9}>                  
                  <div className="why_choose_panel">
                    <div className="why_choose_div" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">
                      <h2>1.</h2>
                      <h3>Payment <br/> System</h3>
                      <h5>Techie is only the utility coin that Allowed in Techs Network as a paymentsystem.</h5>
                    </div>
                    <div className="why_choose_div" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
                      <h2>2.</h2>
                      <h3>Future <br/> Currency</h3>
                      <h5>Future currency for IT Ecosystem.</h5>
                    </div>
                    <div className="why_choose_div" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                      <h2>3.</h2>
                      <h3>Passive <br/> Earnings</h3>
                      <h5>Passive earning platform for IT Professionals.</h5>
                    </div>
                    <div className="why_choose_div" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700">
                      <h2>4.</h2>
                      <h3>B2B Payment <br/> method</h3>
                      <h5>Sole payment method for our paid B2B solutions and systems.</h5>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </section>

        {/* Token Section */}
        <section className="" name="token">
          <div className="container"> 
            <div className="token_sale_section">
              <GridContainer>
                <GridItem sm={12} md={3} lg={3} data-aos="fade-up" data-aos-duration="1000">
                  <h5 className="count_txt">03</h5>
                  <h1 className="main_title">Tokenomics</h1>
                </GridItem>
                <GridItem sm={12} md={9} lg={9}>
                  <p className="home_para">Our ICO Template Will Be A Perfect Platform For Presenting Your Ico Launch. This Landing Page Comes In Great And Clean Design</p>               
                  <div className="ico_details_panel_div">
                    <div className="ico_details_panel">
                      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                        <p>Start Date</p>
                        <h2>August 20, 2022</h2>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                        <p>End Date</p>
                        <h2>December 22, 2022</h2>
                      </div>
                    </div>
                    <div className="ico_details_panel">
                      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                        <p>Total Token amount</p>
                        <h2>200000000</h2>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
                        <p>Exchange Rate</p>
                        <h2>1 BUSD = 1 TNC, 1 BNB = 270.22687 TNC</h2>                        
                      </div>
                    </div>
                    <div className="ico_details_panel">
                      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1000">
                        <p>Currencies</p>
                        <h2>BNB, BUSD</h2>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1200">
                        <p>Minimum Amount</p>
                        <h2>10 BUSD</h2>
                      </div>
                    </div> 
                    </div>                 
                </GridItem>                
              </GridContainer>
              <GridContainer className="mt-5 align-items-center">
                <GridItem sm={12} md={6} lg={7} className="mt-5" data-aos="fade-up" data-aos-duration="1000">                  
                  <img src={require("../assets/images/token_dist_img.png")} className="img-fluid" alt="Icon"/>
                </GridItem>
                <GridItem sm={12} md={6} lg={5} className="mt-5" data-aos="fade-up" data-aos-duration="1000">
                  <div className="chart_asset_details">
                    <ul>
                      <li>
                        <label>Foundation</label>
                        <label>30%</label>
                      </li>
                      <li>
                        <label>Eco-system</label>
                        <label>20%</label>
                      </li>
                      <li>
                        <label>Marketing & Products</label>
                        <label>20%</label>
                      </li>
                      <li>
                        <label>ICO</label>
                        <label>10%</label>
                      </li>
                      <li>
                        <label>Private Sale</label>
                        <label>5%</label>
                      </li>
                      <li>
                        <label>Investors</label>
                        <label>7%</label>
                      </li>
                      <li>
                        <label>Mining</label>
                        <label>5%</label>
                      </li>
                      <li>
                        <label>Airdrops</label>
                        <label>3%</label>
                      </li>
                    </ul>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </section>

        {/* Roadmap Section */}
        <section className="roadmap_section dark_blue_bg" name="roadmap">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
                <h5 className="count_txt">04</h5>
                <h1 className="main_title">Roadmap</h1>                  
                <div className="roadmap_carousel slick_carousel_custom">
                  <Slider {...roadmap_settings}>
                    <div>
                      <div className="roadmap_carousel_div">                        
                        <p>Start Project Communication, Conduct an Airdrop and Regulatory review of the Token. Launch of ICO website.</p>
                        <h2>Q2 2022</h2>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap_carousel_div">
                        <h2>Q3 2022</h2>
                        <p>ICO Launch, Token distribution for airdrop Participants, 1st Phase Development.</p>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap_carousel_div">
                        <p>Launch of 1st Phase(Communities, Badges, Profile Score card), Further listings of the Token.</p>
                        <h2>Q4 2022</h2>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap_carousel_div">
                        <h2>Q1 2023</h2>
                        <p>Live Help, Token burn.</p>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap_carousel_div">
                      <p>Labs and Portfolio Management.</p>
                        <h2>Q4 2023</h2>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap_carousel_div">
                        <h2>Q1 2024</h2>
                        <p>Jobs, Events, Campaigns, Presentations, Token burn and further Listings.</p>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap_carousel_div">                        
                      <p>Accellerator.</p>
                        <h2>Q3 2024</h2>
                      </div>
                    </div>
                    <div>
                      <div className="roadmap_carousel_div">
                        <h2>Q4 2024</h2>
                        <p>Leasing/Licensing Aggregator, Token burn and further Listings.</p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </GridItem>
            </GridContainer>           
          </div>
        </section>

        {/* Team Section */}
       {/* <section className="team_section" name="team">
          <div className="container"> 
            <GridContainer>
              <GridItem sm={12} md={3} lg={3} data-aos="fade-up" data-aos-duration="1000">
                <h5 className="count_txt">05</h5>
                <h1 className="main_title">Our Team Member</h1>
              </GridItem>
              <GridItem sm={12} md={9} lg={9} data-aos="fade-up" data-aos-duration="1000">
                <div className="slick_carousel_custom">
                  <Slider {...team_settings}>
                    <div>
                      <div className="team_panel">
                        <div className="team_panel_holder">
                          <div className="team_item_image">
                            <img src={require("../assets/images/team_01.jpg")} alt="Team" className="img-fluid" />
                          </div>
                          <h4 className="team_item_title">Veronica Johnson</h4>
                          <div className="team_item_position">Founder & COO</div> 
                          <a className="team_item_details" href="javascript:void(0)"><span>+</span></a>
                        </div>
                        <div className="team_panel_hover team_bg_image" style={{ backgroundImage: `url(${require("../assets/images/team_01.jpg")})` }}>
                          <div className="team_panel_inner">
                            <div className="team_item_desc">Lead the team of passionate developers, designers and the strategists with a thought.</div>
                            <h4 className="team_item_title">Veronica Johnson</h4>
                            <div className="team_item_position">Founder & COO</div>
                            <div className="team_item_social">
                              <a href="#"><i className="fab fa-facebook-f"></i></a>
                              <a href="#"><i className="fab fa-twitter"></i></a>
                              <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="team_panel">
                        <div className="team_panel_holder">
                          <div className="team_item_image">
                            <img src={require("../assets/images/team_02.jpg")} alt="Team" className="img-fluid" />
                          </div>
                          <h4 className="team_item_title">Charlee Mangar</h4>
                          <div className="team_item_position">CEO & CFO</div> 
                          <a className="team_item_details" href="javascript:void(0)"><span>+</span></a>
                        </div>
                        <div className="team_panel_hover team_bg_image" style={{ backgroundImage: `url(${require("../assets/images/team_02.jpg")})` }}>
                          <div className="team_panel_inner">
                            <div className="team_item_desc">Lead the team of passionate developers, designers and the strategists with a thought.</div>
                            <h4 className="team_item_title">Charlee Mangar</h4>
                            <div className="team_item_position">CEO & CFO</div>
                            <div className="team_item_social">
                              <a href="#"><i className="fab fa-facebook-f"></i></a>
                              <a href="#"><i className="fab fa-twitter"></i></a>
                              <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="team_panel">
                        <div className="team_panel_holder">
                          <div className="team_item_image">
                            <img src={require("../assets/images/team_03.jpg")} alt="Team" className="img-fluid" />
                          </div>
                          <h4 className="team_item_title">Eddy Hochard</h4>
                          <div className="team_item_position">Vice President</div> 
                          <a className="team_item_details" href="javascript:void(0)"><span>+</span></a>
                        </div>
                        <div className="team_panel_hover team_bg_image" style={{ backgroundImage: `url(${require("../assets/images/team_03.jpg")})` }}>
                          <div className="team_panel_inner">
                            <div className="team_item_desc">Lead the team of passionate developers, designers and the strategists with a thought.</div>
                            <h4 className="team_item_title">Eddy Hochard</h4>
                            <div className="team_item_position">Vice President</div>
                            <div className="team_item_social">
                              <a href="#"><i className="fab fa-facebook-f"></i></a>
                              <a href="#"><i className="fab fa-twitter"></i></a>
                              <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="team_panel">
                        <div className="team_panel_holder">
                          <div className="team_item_image">
                            <img src={require("../assets/images/team_04.jpg")} alt="Team" className="img-fluid" />
                          </div>
                          <h4 className="team_item_title">Stuard Black</h4>
                          <div className="team_item_position">Business Developmnent</div> 
                          <a className="team_item_details" href="javascript:void(0)"><span>+</span></a>
                        </div>
                        <div className="team_panel_hover team_bg_image" style={{ backgroundImage: `url(${require("../assets/images/team_04.jpg")})` }}>
                          <div className="team_panel_inner">
                            <div className="team_item_desc">Lead the team of passionate developers, designers and the strategists with a thought.</div>
                            <h4 className="team_item_title">Stuard Black</h4>
                            <div className="team_item_position">Business Developmnent</div>
                            <div className="team_item_social">
                              <a href="#"><i className="fab fa-facebook-f"></i></a>
                              <a href="#"><i className="fab fa-twitter"></i></a>
                              <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="team_panel">
                        <div className="team_panel_holder">
                          <div className="team_item_image">
                            <img src={require("../assets/images/team_05.jpg")} alt="Team" className="img-fluid" />
                          </div>
                          <h4 className="team_item_title">Melina Thomas</h4>
                          <div className="team_item_position">Global Marketing Officer</div> 
                          <a className="team_item_details" href="javascript:void(0)"><span>+</span></a>
                        </div>
                        <div className="team_panel_hover team_bg_image" style={{ backgroundImage: `url(${require("../assets/images/team_05.jpg")})` }}>
                          <div className="team_panel_inner">
                            <div className="team_item_desc">Lead the team of passionate developers, designers and the strategists with a thought.</div>
                            <h4 className="team_item_title">Melina Thomas</h4>
                            <div className="team_item_position">Global Marketing Officer</div>
                            <div className="team_item_social">
                              <a href="#"><i className="fab fa-facebook-f"></i></a>
                              <a href="#"><i className="fab fa-twitter"></i></a>
                              <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </GridItem>                
            </GridContainer>
          </div>
        </section> */}

        {/* FAQ Section */}
        <section className="faq_section dark_blue_bg" name="faq">
          <div className="container">  
            <GridContainer>
              <GridItem sm={12} md={3} lg={3} data-aos="fade-up" data-aos-duration="1000">
                <h5 className="count_txt">06</h5>
                <h1 className="main_title">FAQ’S</h1>
              </GridItem>
              <GridItem sm={12} md={9} lg={9} className="m-auto" data-aos="fade-up" data-aos-duration="1000">                
                <div className="faq_setion_panel mt-5">
                  {faqlist && faqlist.length>0 && faqlist.map((rec,i)=>{
                    let j = i++;
                    return(
                      <Accordion expanded={expanded === 'panel'+j} onChange={handleChangeFaq('panel'+j)}>
                        <AccordionSummary aria-controls={"panel"+j+"bh-content"} id={"panel"+j+"bh-header"}>
                          <h2 className="accordian_head">{rec.question}</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="accordian_para">{rec.answer}</p>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })
                  }

                  {/*<Accordion expanded={expanded === 'panel2'} onChange={handleChangeFaq('panel2')}>
                    <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-header">
                      <h2 className="accordian_head">What is ICO?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Etiam nulla diam, fermentum at orci sed, viverra suscipit enim. Aliquam viverra ex nec orci viverra, a tristique justo dapibus. Maecenas ligula odio, semper at nulla nec, fringilla ornare dui. Praesent in venenatis enim. Donec nec magna vitae justo ultrices rhoncus.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFaq('panel3')}>
                    <AccordionSummary aria-controls="panel3bh-content" id="panel3bh-header">
                      <h2 className="accordian_head">How can I participate in the ICO Token sale?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFaq('panel4')}>
                    <AccordionSummary aria-controls="panel4bh-content" id="panel4bh-header">
                      <h2 className="accordian_head">How do I benefit from the ICO Token?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel5'} onChange={handleChangeFaq('panel5')}>
                    <AccordionSummary aria-controls="panel5bh-content" id="panel5bh-header">
                      <h2 className="accordian_head">Why choose ICO?</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                    </AccordionDetails>
                  </Accordion> */}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </section>

        {/* Contact Section */}
        <section className="contact_section" name="contact">
          <div className="container">  
            <GridContainer>
              <GridItem sm={12} md={3} lg={3} data-aos="fade-up" data-aos-duration="1000">
                <h5 className="count_txt">07</h5>
                <h1 className="main_title">Contact Us</h1>
              </GridItem>
              <GridItem sm={12} md={9} lg={9} className="m-auto" data-aos="fade-up" data-aos-duration="1000">                
                <div className="contact_address">
                  <div>
                    <img src={require("../assets/images/contact_icon_01.png")} alt="Contact Icon" className="img-fluid" />
                    <h4>{cmslist && cmslist.address ?cmslist.address : ""}</h4>
                  </div> 
                  <div>
                    <img src={require("../assets/images/contact_icon_02.png")} alt="Contact Icon" className="img-fluid" />
                    <h4><a href="mailto:support@techs.network"></a>{cmslist && cmslist.email ?cmslist.email : ""}</h4>
                  </div>
                  {/*<div>
                    <img src={require("../assets/images/contact_icon_03.png")} alt="Contact Icon" className="img-fluid" />
                    <h4><a href="tel:+84 0977425031">{cmslist && cmslist.mobile ?cmslist.mobile : ""}</a></h4>
                  </div>*/}
                </div>

                <form className="auth_form px-3 pt-4 pb-0">
                  <div className="row">
                    <div className="form-group col-md-12 col-lg-6">
                      <label for="full_name">Full Name</label>
                      <input type="text" className="auth_inp" id="full_name" onChange={namechange} value={name}/>
                    </div>
                    <div className="form-group col-md-12 col-lg-6">
                      <label for="email">Email</label>
                      <input type="email" className="auth_inp" id="email" onChange={emailchange} value={email}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-12 col-lg-12">
                      <label for="message">Tell us what we can help you with</label>
                      <textarea className="auth_inp" rows="4" id="message" onChange={msgchange} value={msg}></textarea>
                    </div>
                  </div>
                  <div className="text-left">
                    <Button type="button" variant="contained" className="primary_btn mb-4" onClick={()=>submitquery()}>Submit</Button>
                  </div>
                </form>
              
              </GridItem>
            </GridContainer>
          </div>
        </section>

        <FooterHome />
      </div>
    </div>
  );
}

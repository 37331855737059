import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './index.css';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// pages for this product
import Home from "views/home.js";
import Buy from "views/buy.js";

ReactDOM.render(
  <BrowserRouter basename="/">
  <ToastContainer />
    <Switch>      
      <Route path="/buy" component={Buy} />
      <Route path="/" component={Home} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

import React, {useState,useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinksHome from "components/Header/HeaderLinksHome.js";
import FooterHome from "components/Footer/FooterHome.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Button, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import { toastAlert } from "../helper/toastAlert";
import Select from 'react-select';
import config from "../lib/config";
import ABI from "../ABI/icoabi.json";
import bnbtousdabi from "../ABI/bnbtousd.json";
import BUSD from "../ABI/busdabi.json";
import tokenabi from "../ABI/token.json";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import axios from "axios";
import { getCurAddr , update_deposit , userdepositlist } from "../action/controller"
import moment from 'moment';


const busdContractAddr = config.busdcontract;
const contractAddr = config.icocontract;
const bnbtousdcontract = config.bnbtousd;
const tokencontract = config.tokencontract

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);
const supplyoptions = [
  { value: 'BNB', label: 'BNB' },
  { value: 'BUSD', label: 'BUSD' },
]

// Trade History Table
const data = [
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "BTC", amount: '3,000.00', earnedToken: "500",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "ETH", amount: '2,050.00', earnedToken: "10000",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "ETH", amount: '3,000.00', earnedToken: "100000",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "BTC", amount: '5,100.50', earnedToken: "999999",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "BTC", amount: '3,000.00', earnedToken: "378520",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "ETH", amount: '3,000.00', earnedToken: "685123",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "ETH", amount: '3,000.00', earnedToken: "247963",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "ETH", amount: '3,000.00', earnedToken: "7412",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "BTC", amount: '3,000.00', earnedToken: "789456",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "BTC", amount: '3,000.00', earnedToken: "999999",},
  { datetime: "2022-07-28 T06:20:33", transID: "0x79d4234bee404b84a44c81d9c902de863011b01be83782efbefb5aa2e57a1a01", type: "BTC", amount: '3,000.00', earnedToken: "999999",},
  ];

const dashboardRoutes = [];
export default function Buy(props) {  

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {	  
      return {
      ...styles,
      // backgroundColor: isFocused ? "null" : null,		
      };
    }
  };
  
  const { ...rest } = props;
  const classes = useStyles();

  const [currAddress, setcurrentAddress] = React.useState('');
  const [accept, setaccept] = React.useState(false);
  const [coinvalue, setcoinvalue] = React.useState(0);
  const [tokenvalue, settokenvalue] = React.useState(0);
  const [coin, setcoin] = React.useState('BNB');
  const [tokenusd, settokenusd] = React.useState(0);
  const [show, setshow] =  React.useState(false)
  const [userbnbbal, setuserbnbbal] =  React.useState(false)
  const [userbusdbal, setuserbusdbal] =  React.useState(false)
  const [progress, setprogress] =  React.useState(false)
  const [bnbusd,setbnbusd] =  React.useState(0)
  const [records,setrecords] =  React.useState([])

  const columns = [      
    {
      name: 'Date & Time',
      selector: 'datetime',
      sortable: false,
      cell: (item) => {
        return(
            moment(item.createdAt).format("YYYY MM DD HH:MM:SS A")
          )
      }
    },
    {
      name: 'Transaction Id',
      selector: 'trxid',
      sortable: false,
      width: "400px",
      cell:(item) => {
        return(
          <a href={config.bscurl+"tx/"+item.trxid} target="_blank">{item.trxid}</a>
        )
      },
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: false,
      cell:(item) => {
        return(
          item.amount + "    "+item.currency
        )
      },
    },
    {
      name: 'Earned Token',
      selector: 'gainvalue',
      sortable: false,
      cell:(item) => {
        return(
          item.gainvalue + "    TNC"
        )
      },
    },
  ];

  useEffect(()=>{
    getcoinvalue()
    getbalance();
    getrecords();
  },[])

   /*------------developer code starts---------------------------------------*/

  async function alertconnect(){
    toastAlert('error', "Connect Wallet", 'excerciseerr');
  }

  async function checkchange(termsandcon){
    setaccept(termsandcon);
  }

  async function getrecords(){
    let data = {
      address : localStorage.getItem("account")
    }
    let list = await userdepositlist(data)
    console.log(list,"==========listlist")
    if(list && list.data && list.data.data){
      setrecords(list.data.data)
    }
  }

   const tokenchange = (e) => {
    settokenvalue(e.target.value);
    console.log(e.target.value,"========valvalval1char")
    if(e.target.value!="" && e.target.value!=undefined  && e.target.value!=null && parseFloat(e.target.value)>0){
      if(coin=="BUSD"){
        var usd2 =(parseFloat(e.target.value)/parseFloat(tokenusd));
        setcoinvalue(usd2.toFixed(5));
      }else{
        var usd2 =(parseFloat(e.target.value)/parseFloat(bnbusd));
        setcoinvalue(usd2.toFixed(5));
      }
    }else{
     setcoinvalue(0);
    }
  }

  const inputchange = (e) => {
    setcoinvalue(e.target.value);
    if(e.target.value!="" && e.target.value!=undefined  && e.target.value!=null && parseFloat(e.target.value)>0){
     if(coin=="BUSD"){
      var trxtousdt =(parseFloat(e.target.value)*parseFloat(tokenusd));
      settokenvalue(trxtousdt.toFixed(5));
     }else{
      console.log(bnbusd,"===========",e.target.value)
      var trxtousdt =(parseFloat(e.target.value)*parseFloat(bnbusd));
      settokenvalue(trxtousdt.toFixed(5));
     }
   }else{
    settokenvalue(0)
   }
  }

  const selectchange = (e) =>{
    setcoin(e.value)
    setcoinvalue(0)
    settokenvalue(0)
  }

  async function getcoinvalue(){
    let details = await getCurAddr();
    let web3 =  new Web3(details && details.provider && details.provider!=null && details.provider!=undefined && details.provider!=""?details.provider:window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          var tokenContract = new web3.eth.Contract(ABI,contractAddr);
          var tokenvalue = await tokenContract.methods.getTokenprice().call();
          var tokenusd = parseFloat(tokenvalue)/1000000
          settokenusd(tokenusd) 
          var BNBvalue = await tokenContract.methods.getBnbPrice().call();
          var bnb_val = parseFloat(BNBvalue)/10000000000000000
          setbnbusd(bnb_val)
        } 
      } catch (err) {
          console.log(err,"========erer")
          toastAlert('error', "Please Add Metamask External", 'excerciseerr');
      }
  }

  async function getbalance(){
    let details = await getCurAddr();
    console.log(details,"==========details")
    if(details && details.bnbvalue){
      setuserbnbbal(details.bnbvalue)
    }else{
      setuserbnbbal(0)
    }
    if(details && details.busdvalue){
      setuserbusdbal(details.busdvalue)
    }else{
      setuserbusdbal(0)
    }
    let web3 =  new Web3(details && details.provider && details.provider!=null && details.provider!=undefined && details.provider!=""?details.provider:window.ethereum);
    var result = await web3.eth.getAccounts();
    var busdContract = new web3.eth.Contract(BUSD, busdContractAddr);
    var busdBln = await busdContract.methods.balanceOf(result[0]).call();
    var value = parseFloat(busdBln) / 1000000000000000000;
    setuserbusdbal(value)
  }

  function convert(n){
    var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead,decimal,pow] = n.toString()
     .replace(/^-/,"")
     .replace(/^([0-9]+)(e.*)/,"$1.$2")
     .split(/e|\./);
     return +pow < 0
    ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
    : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
  }

  async function buyfunction(){
    setprogress(true)
    let details = await getCurAddr();
    if(typeof localStorage.getItem('account') !== 'undefined' && localStorage.getItem('account') !== "" && localStorage.getItem('account') !== null && localStorage.getItem('account') !== undefined){
    var currAddress = localStorage.getItem('account');
      let web3 =  new Web3(details && details.provider && details.provider!=null && details.provider!=undefined && details.provider!=""?details.provider:window.ethereum);
      let netid = await web3.eth.net.getId();
       if (netid != config.networkVersion) {
         toastAlert('error', "Connect BSC Network", 'excerciseerr');
         setprogress(false)
       }else{
        if(coinvalue!=null && coinvalue!=undefined && parseFloat(coinvalue)>0 && coinvalue!=""){
          if(tokenvalue!=null && tokenvalue!=undefined && parseFloat(tokenvalue)>0 && tokenvalue!=""){
             var token_contract =  new web3.eth.Contract(tokenabi, tokencontract); 
             let contractbalance = await token_contract.methods.balanceOf(contractAddr).call();
             contractbalance = parseFloat(contractbalance)/parseFloat(config.decimalvalues)
             if(parseFloat(tokenvalue)<=parseFloat(contractbalance)){
                if(coin == "BUSD"){
                  if(parseFloat(coinvalue)<parseFloat(userbusdbal)){
                    var contract = new web3.eth.Contract(ABI, contractAddr); 
                    var budcontrct =  new web3.eth.Contract(BUSD, busdContractAddr); 
                    let amt = parseFloat(coinvalue)* parseFloat(config.decimalvalues);
                    amt = await convert(amt);
                   budcontrct.methods.approve(contractAddr,amt.toString())
                    .send({ from: currAddress })
                    .then(async function (result1,error1) { 
                    if(result1){
                     contract.methods.depositBUSD(amt.toString())
                    .send({ from: currAddress })
                    .then(async function (result,error) { 
                    console.log(result,error,"======================getgetget")
                    if(result){
                      let savedata = {
                        trxhash : result.transactionHash,
                        currency : coin,
                        deposit : coinvalue,
                        gain : tokenvalue,
                        address : currAddress
                      }
                      await update_deposit(savedata)
                      getrecords();
                      setprogress(false);
                      toastAlert('success', "Successfully completed", 'excerciseerr');
                      setcoinvalue(0)
                      settokenvalue(0)
                    }
                    }).catch(function(error){
                      setprogress(false)
                      toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                      console.log(error,"======================error")
                    })  
                   }else{
                    setprogress(false)
                    toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                   }
                   }).catch(function(error){
                      setprogress(false)
                      toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                      console.log(error,"======================error")
                    })     
                  }else{
                    setprogress(false)
                    toastAlert('error', "Insufficient Balance", 'excerciseerr');              
                  }
                 }else{
                  if(parseFloat(coinvalue)<parseFloat(userbnbbal)){
                    var contract = new web3.eth.Contract(ABI, contractAddr); 
                    let amt = parseFloat(coinvalue)* parseFloat(config.decimalvalues);
                    amt = await convert(amt);
                    await contract.methods.depositBNB()
                    .send({ from: currAddress , value : amt.toString()})
                    .then(async function (result,error) { 
                    console.log(result,error,"======================getgetget")
                    if(result){
                       let savedata = {
                        trxhash : result.transactionHash,
                        currency : coin,
                        deposit : coinvalue,
                        gain : tokenvalue,
                        address : currAddress
                      }
                      await update_deposit(savedata)
                      setprogress(false);
                      toastAlert('success', "Successfully completed", 'excerciseerr');
                      setcoinvalue(0)
                      settokenvalue(0)
                    }
                    }).catch(function(error){
                      setprogress(false)
                      toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                      console.log(error,"======================error")
                    })
                  }else{
                    setprogress(false)
                    toastAlert('error', "Insufficient Balance", 'excerciseerr');              
                  }
                 }
             }else{
               setprogress(false)
               toastAlert('error', "Contract does not have enough Reward", 'excerciseerr');
             }
         }else{
          setprogress(false)
          toastAlert('error', "Invalid Input", 'excerciseerr');
         }
        }else{
          setprogress(false)
          toastAlert('error', "Invalid Input", 'excerciseerr');
        }
       }
    }else{
      setprogress(false)
      toastAlert('error', "Connect BSC Network", 'excerciseerr');
    }
  }


  return (
    <div className="header_home">  
        <ScrollToTopOnMount />    
        <Header
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.svg")} alt="logo" />}
        rightLinks={<HeaderLinksHome />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        {...rest}
      />
      <div className="pageHeader">
        <div className="dash_top_bar">
          <div className="container inner_pageheader">
            <GridContainer className="mt-5">              
              <GridItem md={12} lg={12}>              
                {/* Wallet Address Box */}
                <div className="wallet_address_panel dash_inner_header">
                  <h2>Buy Token</h2>                  
                  <form className="auth_form px-5 pt-4 pb-5">
                    <div className="row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4" className="auth_label">Select Token <span className="required">*</span></label>
                        <Select value = { supplyoptions.filter(option => option.label === coin )} options={supplyoptions} styles={colourStyles} className="customSelect" onChange={selectchange} />
                      </div>                      
                    </div>
 
                    <div className="row">
                      <div className="form-group col-md-6">
                        <div className="d-flex justify-content-between align-items-center">
                          <label htmlFor="inputEmail4" className="auth_label">Amount <span className="required">*</span></label>                          
                        </div>
                        <div className="form_text_prepend">
                          <input type="text" className="auth_inp" id="inputPassword4" placeholder="0" onChange={inputchange} value={coinvalue}/>
                          <span>{coin}</span>
                        </div>
                        <div className="avil_balance">Balance: <span>{coin=="BNB"?parseFloat(userbnbbal).toFixed(4):parseFloat(userbusdbal).toFixed(4)} {coin}</span></div>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4" className="auth_label">You Will Get</label>
                        <div className="form_text_prepend">
                          <input type="text" className="auth_inp" id="inputEmail4" value={tokenvalue} onChange={tokenchange}/>
                          <span>TNC</span>
                        </div>
                      </div>
                    </div>
                    {localStorage.getItem("account") && localStorage.getItem("account")!=null && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!="" && progress ? (
                      <div className="text-center mt-3">
                        <Button variant="contained" className="primary_btn">In Progress..,,Please Wait</Button>
                      </div> )
                     :
                    localStorage.getItem("account") && localStorage.getItem("account")!=null && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!=""  ? (
                      <div className="text-center mt-3">
                        <Button variant="contained" className="primary_btn" onClick={()=>buyfunction()}>Buy Token</Button>
                      </div> )   
                    :
                    <div className="text-center mt-3">
                        <Button variant="contained" className="primary_btn">Connect Wallet</Button>
                      </div>
                    }                
                  </form>
                </div>

                {/* Buy History Table */}
                <div className="table_box">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2>Buy History</h2>
                  </div>
                  <div className="mt-4">
                    <DataTable columns={columns} data={records} noHeader pagination  />
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div> 
      </div>
      <FooterHome/>
      
      
    </div>
  );
}

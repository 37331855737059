let NODE_ENV = 'production';

let Front_URL = '';
let Base_URL = '';
let rpcURL = '';
let decimalvalues = 1000000000000000000;
let toFixed = 5;
let networkVersion = '';
let bscurl = '';
let adminAddress = ""
let tokencontract = ""
let busdcontract = ""
let icocontract = ""
let bnbtousd = ""

let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}

if (NODE_ENV === "production") {
    Front_URL = 'https://ico.techs.network/';
    Base_URL = 'https://api.techs.network';
    networkVersion = 56;
    rpcURL = 'https://bsc-dataseed.binance.org/' ;
    bscurl = "https://bscscan.com/"
    adminAddress = "0x373F886904048975034488320E37d171E092C135"
    tokencontract = "0x170b47f039d006396929F7734228fFc53Ab155b2"
    busdcontract = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
    icocontract = "0x9c4af6387dDe442b841210Cd70A257fBB31bD383"
    bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE"
}
else if (NODE_ENV === "demo") {
    Front_URL = 'https://technsnetwork.wealwin.com/';
    Base_URL = 'https://technsnetworkapi.wealwin.com';
    networkVersion = 97;
    rpcURL = 'https://bsc-dataseed.binance.org/' ;
    bscurl = "https://testnet.bscscan.com/";
    adminAddress = "0xdC6A32D60002274A16A4C1E93784429D4F7D1C6a"
    tokencontract = "0x6F40A40e922d0Cd38461B5f6049C51c6D5A889E8"
    busdcontract = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F"
    icocontract = "0xBccDE0f9fEC5E07ab0B390Ea13365692f87DDAF3"
    bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE"

}
else {
    Front_URL = 'http://localhost:3000';
    Base_URL = 'http://localhost:3518';
    networkVersion = 97;
    rpcURL = 'https://bsc-dataseed.binance.org/' ;
    bscurl = "https://testnet.bscscan.com/"
}

let key = {
    Front_URL: Front_URL,
    Base_URL: Base_URL,
    decimalvalues:decimalvalues,
    toFixed:toFixed,
    "rpcURL" : rpcURL,
    networkVersion: networkVersion,
    toasterOption: toasterOption,
    adminAddress: adminAddress,
    tokencontract: tokencontract,
    icocontract: icocontract,
    busdcontract: busdcontract,
    bnbtousd: bnbtousd,
    bscurl : bscurl
};

export default key;

import axios from 'axios';
import config from '../lib/config';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import busdabi from '../ABI/busdabi';
import tokenabi from '../ABI/token';

export const update_deposit = async (payload) => {
  try{
    let resp = await axios({
      'method': 'post',
      'url': `${config.Base_URL}/api/updatedeposit`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const queryupdate = async (payload) => {
  try{
    let resp = await axios({
      'method': 'post',
      'url': `${config.Base_URL}/api/queryupdate`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const cmsdetails = async () => {
  try{
    let resp = await axios({
      'method': 'get',
      'url': `${config.Base_URL}/api/cmsdetails`,
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const allfaq = async () => {
  try{
    let resp = await axios({
      'method': 'get',
      'url': `${config.Base_URL}/api/allfaq`,
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const getdeposit = async () => {
   try{
    let resp = await axios({
      'method': 'get',
      'url': `${config.Base_URL}/api/getdeposit`,
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const userdepositlist = async (payload) => {
  try{
    let resp = await axios({
      'method': 'post',
      'url': `${config.Base_URL}/api/userdepositlist`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const getCurAddr = async () => {
  if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")!=null && localStorage.getItem("wallettype")!=undefined && localStorage.getItem("wallettype")!="" && localStorage.getItem("wallettype")=="trust"){
    try{
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56,
      });

      provider.on("connect", () => {
      });
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if(result[0]!=undefined){
        if (config.networkVersion  == network) {
          localStorage.setItem("wallettype","trust")
          localStorage.setItem("account",result[0]);
          let mybal = await web3.eth.getBalance(result[0])
          var busdcontract = new web3.eth.Contract(busdabi, config.busdcontract);
          var tokencontract = new web3.eth.Contract(tokenabi, config.tokencontract)
          let value = await busdcontract.methods.balanceOf(result[0]).call();
          let tokenvalue = await tokencontract.methods.balanceOf(result[0]).call();
          value = parseFloat(value)/1000000000000000000
          mybal = parseFloat(mybal)/1000000000000000000
          tokenvalue = parseFloat(tokenvalue)/1000000000000000000
          var data = {
            address : result[0],
            busdvalue : value,
            provider : provider,
            bnbvalue  : mybal,
            "tokenvalue" : tokenvalue
          }
          return data
        } else {
         await provider.disconnect();
        }
     }
    }catch(err){
      localStorage.clear();
      window.location.reload()
    }
  }
  else{
   if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum.enable()
          const web3 = new Web3(window.ethereum);
          if (window.ethereum) {
            let netid = await web3.eth.net.getId();
            if (
              netid ==
              config.networkVersion
            ) {
              var result = await web3.eth.getAccounts();
              let mybal = await web3.eth.getBalance(result[0])
              console.log(mybal,"=======mybasl")
              var busdcontract = new web3.eth.Contract(busdabi, config.busdcontract);
              let value = await busdcontract.methods.balanceOf(result[0]).call();
              value = parseFloat(value)/1000000000000000000
              mybal = parseFloat(mybal)/1000000000000000000
              var data = {
                address : result[0],
                busdvalue : value,
                bnbvalue  : mybal
              }
              console.log(data,"===============datadatadatadatadata")
              return data 
            }
          }
        }
      }catch(err){
        console.log(err,"===========errrrrrrrrrrrdata")
     }
    }
  }
}
/*eslint-disable*/
import React,{useState,useEffect} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { NavLink } from "react-router-dom";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from 'react-scroll'
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";
import { cmsdetails } from "../../action/controller.js"

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const [cmslist,setcmslist] = React.useState({});
  const [showpath,setshowpath] = React.useState(false);

  async function getcms(){
    let records = await cmsdetails();
    if(records && records.data && records.data.data){
      setcmslist(records.data.data)
    }
  }

  useEffect(()=>{
    getcms()
    var pathname = window.location.pathname;
    if(pathname !="/buy"){
      setshowpath(true)
    }
  },[])

  return (
    <footer className={footerClasses + " footer_home"}>
      <div className="container">
        <div className="footer_bottom_home">
          <div className="footer_bottom_home_left">
            <ul>
              <li className="footerHover">
                {showpath ?
                  <Link to="home" spy={true} smooth={true} duration={250} color="transparent">Home</Link>
                  :
                  <NavLink to="home" spy={true} smooth={true} duration={250} color="transparent">Home</NavLink>
                }
              </li>
              <li className="footerHover">
                {showpath ?
                  <Link to="about" spy={true} smooth={true} duration={250} color="transparent">About Us</Link>
                  :
                  <NavLink to="about" spy={true} smooth={true} duration={250} color="transparent">About Us</NavLink>
                }
              </li>
              <li className="footerHover">
                {showpath ?
                  <Link to="why_choose" spy={true} smooth={true} duration={250} color="transparent">Why ICO?</Link>
                  :
                  <NavLink to="why_choose" spy={true} smooth={true} duration={250} color="transparent">Why ICO?</NavLink>
                } 
              </li>
              <li className="footerHover">
               {showpath ?
                  <Link to="token" spy={true} smooth={true} duration={250} color="transparent">Tokenomics</Link>
                :
                  <NavLink to="token" spy={true} smooth={true} duration={250} color="transparent">Tokenomics</NavLink>
                }
              </li>
              <li className="footerHover">
               {showpath ?
                  <Link to="roadmap" spy={true} smooth={true} duration={250} color="transparent">Roadmap</Link>
                  :
                  <NavLink to="roadmap" spy={true} smooth={true} duration={250} color="transparent">Roadmap</NavLink>
                }
              </li>
              <li className="footerHover">
                {showpath ?
                  <Link to="faq" spy={true} smooth={true} duration={250} color="transparent">FAQ's</Link>
                  :
                  <NavLink to="faq" spy={true} smooth={true} duration={250} color="transparent">FAQ's</NavLink>
                }
              </li>
              <li className="footerHover">
               {showpath ?
                  <Link to="contact" spy={true} smooth={true} duration={250} color="transparent">Contact</Link>
                 :
                  <NavLink to="contact" spy={true} smooth={true} duration={250} color="transparent">Contact</NavLink>
                }
              </li>
            </ul>
            <div className="copyright_txt">© Copyright {" "}{1900 + new Date().getYear()}{" "}by <Link to="/" className={aClasses}>Techs Network Inc.</Link>
              {" "}All Rights Reserved.
            </div>
          </div>
          <div className="login_social_links">            
            <ul className="social_links">
              <li><a href={cmslist.telegram} target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
              <li><a href={cmslist.twitter} target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href={cmslist.linkedin} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href={cmslist.reddit} target="_blank"><i className="fab fa-reddit-alien"></i></a></li>
              <li><a href={cmslist.youtube} target="_blank"><i className="fab fa-youtube"></i></a></li>                          
              <li><a href={cmslist.instagram} target="_blank"><i className="fab fa-instagram"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};

import React, { useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { Link } from "react-router-dom";
import config from "../../lib/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import $ from "jquery";
import { toastAlert } from "../../helper/toastAlert";

const useStyles = makeStyles(styles);


export default function Header(props) {
  const timerRef = useRef(null);

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [accounts, setaccount]     = React.useState();
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Link to="/" className="logo_div">{brand}</Link>;

  async function connectTrustWallet(){
    try{
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56,
      });

      provider.on("connect", () => {
      });
      console.log("afffffttttterrrrrrrconnnecr",provider)
      await provider.enable();
      console.log("aftttttterrrrrrrrenneneneab")
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if(result[0]!=undefined){
        if (config.networkVersion == network) {
          localStorage.setItem("wallettype","trust")
          localStorage.setItem("account",result[0]);
          window.location.href="/buy"
        } else {
         await provider.disconnect();
          toastAlert('error', "please select BSC chain network on your wallet", 'network');
        }
      }
    }catch(err){
      console.log(err,"=======errr")
      localStorage.clear();
      window.location.reload()
    }
  }

  async function connectClick() {
    try{
      const web3 = new Web3(window.ethereum);
      const result = await web3.eth.getAccounts();
      web3.eth.net.getId().then(res =>{
        if(result[0]!=undefined){
          if(res === config.networkVersion){
             toastAlert('success', "Connected Wallet", 'excerciseerr');
             setaccount(result[0]);
             localStorage.setItem('account',result[0]);
             localStorage.setItem('wallettype',"metamask");
             window.location.href="/buy"
          }else{
            console.log(res,config.networkVersion,"======config.networkVersionconfig.networkVersionconfig.networkVersion")
            toastAlert('error', "Connect BSC Network", 'excerciseerr');
            setaccount("")
            return false;
          }
        }
      });
    }catch(err){
      console.log(err,"======errrororor")
    }
  }

  window.addEventListener('load',async (event) => {
    if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")=="metamask"){
      event.preventDefault();
      var provider = window.ethereum;
      var web3 = new Web3(provider);
      if(window.ethereum) {
        window.ethereum.on('accountsChanged', async function (accounts) {
          if(timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async() => {
            var result = await web3.eth.getAccounts()
            var setacc = result[0];
            localStorage.setItem("account",result[0])
            window.location.reload()
          }, 1000);
        })

        window.ethereum.on('networkChanged',async function (networkId) {
          var provider = window.ethereum;
          var web3 = new Web3(provider);
          if(networkId == config.networkversion){
            if(timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(async() => {
              var result = await web3.eth.getAccounts()
              var setacc = result[0];
              localStorage.setItem("account",result[0])
              props.Set_Accounts(setacc);
              window.location.reload()
            }, 1000);
          }
          else {
            localStorage.clear()
            window.location.reload()
          }
        })
      }
    }
  })

  return (
    <div>
      <AppBar className={appBarClasses}>
        <Toolbar className="container">
          {leftLinks !== undefined ? brandComponent : null}
          <div className={classes.flex}>
            {leftLinks !== undefined ? (
              <Hidden mdDown implementation="css">
                {leftLinks}
              </Hidden>
            ) : (
              brandComponent
            )}
          </div>
          <Hidden mdDown implementation="css">
            {rightLinks}
          </Hidden>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              className="hamburger_btn"
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden lgUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper + " mobile_nav"
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
      
       {/* Connect Wallet Modal */}
      <div className="modal fade primary_modal" id="connect_wallet_modal" tabindex="-1" aria-labelledby="connect_walletModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">Connect Wallet</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button" onClick={()=>connectClick()}>
                      <img src={require("../../assets/images/metamask.png")} height="28" width="28" className="mr-1 v-align-middle" /> <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button" onClick={()=>connectTrustWallet()}>
                      <img src={require("../../assets/images/walletconnect.png")} height="28" width="28" className="mr-1 v-align-middle" /> <span>WalletConnect</span>
                    </button>
                  </a>
              {/* <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img src={require("../../assets/images/torus.png")} height="28" width="28" className="mr-1 v-align-middle" /> <span>Torus</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img src={require("../../assets/images/portis.png")} height="28" width="28" className="mr-1 v-align-middle" /> <span>Portis</span>
                    </button>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
